import React, { useState } from "react";
import {
  Box,
  Container,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTheme } from "@mui/material/styles";

const FAQ = () => {
  const theme = useTheme();
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const questionsAnswers = [
    {
      question: "O que é a plataforma Zero Multas?",
      answer:
        "A Zero Multas é uma ferramenta que utiliza inteligência artificial para criar petições de defesa de multas de trânsito, de forma rápida e acessível.",
    },
    {
      question: "Como faço para gerar um recurso de multa?",
      answer:
        "Você só precisa fornecer os dados solicitados, efetuar o pagamento e, em poucos minutos, a plataforma gerará sua petição pronta para download.",
    },
    {
      question: "É necessário contratar um advogado para usar a plataforma?",
      answer:
        "Não, a plataforma foi desenvolvida para permitir que você mesmo gere suas petições sem precisar de assistência jurídica externa.",
    },
    {
      question: "Quanto custa utilizar a Zero Multas?",
      answer:
        "O custo para gerar uma petição é R$ 25,00, pago diretamente na plataforma durante o processo.",
    },
    {
      question: "A Zero Multas garante que meu recurso será aceito?",
      answer:
        "Não, a aceitação do recurso depende exclusivamente da análise do órgão responsável pela multa.",
    },
    {
      question: "Quais informações são necessárias para criar minha petição?",
      answer:
        "Você precisará informar seus dados pessoais, os detalhes da multa e qualquer documentação relevante.",
    },
    {
      question: "Como recebo minha petição após o pagamento?",
      answer:
        "Após a confirmação do pagamento, o recurso estará disponível para download diretamente na plataforma.",
    },
    {
      question: "Posso editar minha petição após gerá-la?",
      answer:
        "Sim, você pode personalizar sua petição antes de entregá-la, mas é recomendado verificar todas as informações antes de concluir o processo.",
    },
    {
      question: "O que faço se encontrar um erro na petição gerada?",
      answer:
        "Se encontrar um erro, entre em contato com a equipe de suporte pelo e-mail contato@avarin.com.br para que possamos ajudá-lo.",
    },
    {
      question: "Por quanto tempo a plataforma armazena minhas petições?",
      answer:
        "As petições ficam disponíveis por 30 dias após a geração. Após esse período, serão removidas dos nossos servidores.",
    },
  ];

  return (
    <Box sx={{ bgcolor: theme.palette.background.default, py: 4 }}>
      <Container
        maxWidth="md"
        sx={{
          bgcolor: theme.palette.background.paper,
          boxShadow: theme.shadows[3],
          p: 4,
          borderRadius: theme.shape.borderRadius,
        }}
      >
        <Typography variant="h4" gutterBottom>
          Perguntas Frequentes
        </Typography>
        {questionsAnswers.map((item, index) => (
          <Accordion
            key={index}
            expanded={expanded === `panel${index}`}
            onChange={handleChange(`panel${index}`)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${index}-content`}
              id={`panel${index}-header`}
            >
              <Typography variant="h6">{item.question}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body1">{item.answer}</Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Container>
    </Box>
  );
};

export default FAQ;
