import React, { useState } from 'react';
import {
  Box,
  Button,
  Typography,
  Container,
  Grid,
  Stepper,
  Step,
  StepLabel,
  Card,
  CardContent,
  CircularProgress,
} from '@mui/material';
import {
  CheckCircle,
  Gavel,
  Speed,
  Error,
  LocalPolice,
  Security,
} from '@mui/icons-material';
import { useEvent } from '../context/EventContext';
import { useNavigate } from 'react-router-dom';

function Home() {
  const navigate = useNavigate();
  const { createEvent } = useEvent();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleRecorrerAgora = async () => {
    setLoading(true);
    setError(null);
    try {
      await createEvent();
      navigate('/define-violation');
    } catch (err) {
      setError('Não foi possível iniciar o processo. Tente novamente mais tarde.');
      console.error('Erro ao criar evento:', err);
    } finally {
      setLoading(false);
    }
  };

  const steps = [
    { label: 'Informe os dados da multa', icon: <Speed color="secondary" sx={{ fontSize: 30 }} /> },
    { label: 'Inteligência Artificial', icon: <Gavel color="secondary" sx={{ fontSize: 30 }} /> },
    { label: 'Baixe sua petição', icon: <CheckCircle color="secondary" sx={{ fontSize: 30 }} /> },
  ];

  return (
    <Box sx={{ backgroundColor: 'background.default', minHeight: '100vh' }}>
      {/* Banner com Animação */}


        <Box
      sx={{
        position: 'relative',
        color: '#fff', // Texto branco
        textAlign: 'center',
        py: { xs: 4, sm: 6 }, // Espaçamento ajustado para mobile
        height: { xs: '50vh', sm: '75vh' }, // Altura menor para mobile
        overflow: 'hidden',
      }}
    >
      {/* Animação do Canva */}
      <video
        src="/videos/animation4.mp4" // Caminho para a animação
        autoPlay
        loop
        muted
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          zIndex: 1,
        }}
      />

      {/* Conteúdo Sobreposto */}
      <Box
        sx={{
          position: 'relative',
          zIndex: 2,
          mx: { xs: 2, sm: 4 }, // Margens horizontais para texto em dispositivos móveis
        }}
      >
        <Typography
          variant="h2"
          sx={{
            my: 2,
            fontSize: { xs: '1.8rem', sm: '2.5rem' }, // Texto menor para mobile
          }}
        >
          Recorra Suas Multas com Facilidade!
        </Typography>
        <Typography
          variant="h5"
          sx={{
            my: 4,
            fontSize: { xs: '1rem', sm: '1.5rem' }, // Texto menor para mobile
          }}
        >
          Geração de petições personalizadas em minutos.
        </Typography>
        <Button
          variant="contained"
          color="secondary"
          size="large"
          onClick={handleRecorrerAgora}
          disabled={loading}
          sx={{
            px: { xs: 4, sm: 6 }, // Botão menor para dispositivos móveis
            py: { xs: 1, sm: 1.5 },
          }}
        >
          {loading ? <CircularProgress size={24} color="inherit" /> : 'Começar Agora'}
        </Button>
      </Box>
    </Box>


      {/* Stepper Section */}
      <Container sx={{ py: 6 }}>
        <Typography variant="h4" align="center" gutterBottom>
          Como Funciona?
        </Typography>
        <Stepper alternativeLabel sx={{ mt: 4 }}>
          {steps.map((step, index) => (
            <Step key={index}>
              <StepLabel
                StepIconComponent={() => (
                  <Box sx={{ textAlign: 'center' }}>
                    {step.icon}
                    <Typography variant="body1" sx={{ mt: 1 }}>
                      {step.label}
                    </Typography>
                  </Box>
                )}
              />
            </Step>
          ))}
        </Stepper>
      </Container>

      {/* Por Que Recorrer Section */}
      <Box sx={{ backgroundColor: 'background.paper', py: 6 }}>
        <Container>
          <Typography variant="h4" align="center" gutterBottom>
            Por que Recorrer de Todas as Multas?
          </Typography>
          <Grid container spacing={4} justifyContent="center" sx={{ mt: 4 }}>
            {[
              {
                icon: <LocalPolice color="secondary" sx={{ fontSize: 50 }} />,
                title: 'Defesa contra Multas Injustas',
                description:
                  'Muitas multas são emitidas incorretamente. Contestar pode reduzir pontos na carteira e manter seu histórico de condução limpo.',
              },
              {
                icon: <Error color="secondary" sx={{ fontSize: 50 }} />,
                title: 'Erros e Inconsistências',
                description:
                  'Erros nas informações da multa podem invalidar a infração. Recorrer garante uma análise detalhada para contestação.',
              },
              {
                icon: <Security color="secondary" sx={{ fontSize: 50 }} />,
                title: 'Proteção Financeira',
                description:
                  'Evite gastos desnecessários. Recorrer de multas pode salvar você de custos altos e preservar sua pontuação.',
              },
            ].map((item, index) => (
              <Grid item xs={12} md={4} key={index}>
                <Card>
                  <CardContent sx={{ textAlign: 'center' }}>
                    {item.icon}
                    <Typography variant="h6" gutterBottom>
                      {item.title}
                    </Typography>
                    <Typography variant="body1" color="text.secondary">
                      {item.description}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>

      {/* Call to Action Section */}
      <Box
        sx={{
          backgroundColor: 'primary.main',
          color: 'secondary.main',
          py: 6,
          textAlign: 'center',
        }}
      >
        <Typography variant="h4" gutterBottom>
          Não perca tempo, recorra agora mesmo!
        </Typography>
        <Typography variant="h6" sx={{ mb: 4 }}>
          Gera sua petição em minutos, sem complicações.
        </Typography>
        <Button
          variant="contained"
          color="secondary"
          size="large"
          onClick={handleRecorrerAgora}
          disabled={loading}
          sx={{ px: 6 }}
        >
          {loading ? <CircularProgress size={24} color="inherit" /> : 'Começar Agora'}
        </Button>
      </Box>

      {/* Erro de API */}
      {error && (
        <Container sx={{ py: 2 }}>
          <Typography variant="body2" color="error" align="center">
            {error}
          </Typography>
        </Container>
      )}
    </Box>
  );
}

export default Home;
