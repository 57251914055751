import React from "react";
import { Box, Typography, Container, Link, List, ListItem } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const PrivacyPolicy = () => {
  const theme = useTheme();

  return (
    <Box sx={{ bgcolor: theme.palette.background.default, py: 4 }}>
      <Container
        maxWidth="md"
        sx={{
          bgcolor: theme.palette.background.paper,
          boxShadow: theme.shadows[3],
          p: 4,
          borderRadius: theme.shape.borderRadius,
        }}
      >
        <Typography variant="h4" gutterBottom>
          Política de Privacidade
        </Typography>

        <Typography variant="h5" gutterBottom>
          1. Introdução
        </Typography>
        <Typography variant="body1" paragraph>
          Bem-vindo ao site da Zero Multas. Esta Política de Privacidade descreve como coletamos,
          usamos, divulgamos e protegemos suas informações pessoais quando você visita
          nosso site e utiliza nossos serviços.
        </Typography>

        <Typography variant="h5" gutterBottom>
          2. Informações que Coletamos
        </Typography>

        <Typography variant="h6">2.1 Informações Pessoais</Typography>
        <Typography variant="body1" paragraph>
          Podemos coletar informações pessoais que você nos fornece diretamente, como:
        </Typography>
        <List>
          <ListItem>Nome</ListItem>
          <ListItem>Endereço de e-mail</ListItem>
          <ListItem>Número de telefone</ListItem>
          <ListItem>Informações de pagamento (quando aplicável)</ListItem>
        </List>

        <Typography variant="h6">2.2 Informações de Uso</Typography>
        <Typography variant="body1" paragraph>
          Coletamos automaticamente informações sobre sua interação com nosso site,
          incluindo:
        </Typography>
        <List>
          <ListItem>Endereço IP</ListItem>
          <ListItem>Tipo de navegador</ListItem>
          <ListItem>Páginas visitadas</ListItem>
          <ListItem>Horário e duração da visita</ListItem>
        </List>

        <Typography variant="h5" gutterBottom>
          3. Uso das Informações
        </Typography>
        <Typography variant="body1" paragraph>
          Utilizamos as informações coletadas para:
        </Typography>
        <List>
          <ListItem>Fornecer, operar e manter nossos serviços</ListItem>
          <ListItem>Melhorar, personalizar e expandir nossos serviços</ListItem>
          <ListItem>Compreender e analisar como você usa nosso site</ListItem>
          <ListItem>Desenvolver novos produtos, serviços, recursos e funcionalidades</ListItem>
          <ListItem>
            Comunicar-se com você, diretamente ou através de nossos parceiros, incluindo
            para atendimento ao cliente, para fornecer atualizações e outras informações
            relacionadas ao site e para fins de marketing e promocionais
          </ListItem>
          <ListItem>Processar suas transações e gerenciar pedidos</ListItem>
          <ListItem>Enviar e-mails periódicos</ListItem>
        </List>

        <Typography variant="h5" gutterBottom>
          4. Compartilhamento de Informações
        </Typography>
        <Typography variant="body1" paragraph>
          Não vendemos, trocamos ou transferimos para terceiros suas informações pessoais.
          Podemos compartilhar suas informações nas seguintes circunstâncias:
        </Typography>
        <List>
          <ListItem>
            Com fornecedores de serviços de terceiros que nos auxiliam em nossas operações
            comerciais
          </ListItem>
          <ListItem>
            Para cumprir com obrigações legais, proteger e defender nossos direitos e
            propriedades, e para cumprir com processos judiciais
          </ListItem>
        </List>

        <Typography variant="h5" gutterBottom>
          5. Segurança das Informações
        </Typography>
        <Typography variant="body1" paragraph>
          Implementamos medidas de segurança para proteger suas informações pessoais contra
          acesso não autorizado, alteração, divulgação ou destruição. No entanto, nenhuma
          transmissão pela Internet ou método de armazenamento eletrônico é 100% seguro.
        </Typography>

        <Typography variant="h5" gutterBottom>
          6. Seus Direitos
        </Typography>
        <Typography variant="body1" paragraph>
          Você tem o direito de:
        </Typography>
        <List>
          <ListItem>Solicitar acesso às suas informações pessoais</ListItem>
          <ListItem>Solicitar correção de informações incorretas ou incompletas</ListItem>
          <ListItem>Solicitar a exclusão de suas informações pessoais</ListItem>
          <ListItem>Retirar seu consentimento a qualquer momento</ListItem>
        </List>

        <Typography variant="h5" gutterBottom>
          7. Cookies
        </Typography>
        <Typography variant="body1" paragraph>
          Utilizamos cookies para melhorar a sua experiência no nosso site. Os cookies são
          pequenos arquivos de texto armazenados no seu dispositivo que nos ajudam a lembrar
          suas preferências e entender como você utiliza o site.
        </Typography>

        <Typography variant="h5" gutterBottom>
          8. Alterações a Esta Política de Privacidade
        </Typography>
        <Typography variant="body1" paragraph>
          Podemos atualizar nossa Política de Privacidade periodicamente. Publicaremos
          quaisquer alterações nesta página e notificaremos você por e-mail ou através de
          uma notificação em nosso site, antes que a alteração se torne efetiva.
        </Typography>

        <Typography variant="h5" gutterBottom>
          9. Contato
        </Typography>
        <Typography variant="body1" paragraph>
          Se você tiver alguma dúvida sobre esta Política de Privacidade, entre em contato
          conosco:
        </Typography>
        <Typography variant="body1">
          Zero Multas
          <br />
          E-mail:{" "}
          <Link href="mailto:contato@zeromultas.cc">contato@zeromultas.cc</Link>
        </Typography>
      </Container>
    </Box>
  );
};

export default PrivacyPolicy;