import React from "react";
import { Box, Typography, Container, List, ListItem, Link } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const TermsOfUse = () => {
  const theme = useTheme();

  return (
    <Box sx={{ bgcolor: theme.palette.background.default, py: 4 }}>
      <Container
        maxWidth="md"
        sx={{
          bgcolor: theme.palette.background.paper,
          boxShadow: theme.shadows[3],
          p: 4,
          borderRadius: theme.shape.borderRadius,
        }}
      >
        <Typography variant="h4" gutterBottom>
          Termo de Uso
        </Typography>

        <Typography variant="body1" paragraph>
          Ao concordar com este termo de uso para utilização da plataforma www.AvarinMultas.com, você declara expressamente ser juridicamente capaz, bem como ler, entender e fornecer aceitação espontânea de todas as cláusulas aqui descritas. Não será possível alegar desconhecimento das cláusulas em juízo, após confirmar a leitura do documento.
        </Typography>

        <Typography variant="h5" gutterBottom>
          Cláusula Primeira - Como Funciona a Zero Multas
        </Typography>
        <List>
          <ListItem>
            <Typography>
              <strong>1.1.</strong> A plataforma Zero Multas é uma ferramenta de geração
              automática de recursos pertencente à empresa NOVAMENTE DESENVOLVIMENTO DE
              PROJETOS TECNOLÓGICOS LTDA, inscrita no CNPJ sob o n. 41.244.251/0001-07,
              com sede à Rua Valério Eugênio, n. 578, Areal, Conselheiro Lafaiete/MG, CEP
              30407-006. A assistência técnica, no que tange ao conteúdo das petições, será
              feita por profissionais e/ou estagiários capacitados.
            </Typography>
          </ListItem>
          <ListItem>
            <Typography>
              <strong>1.2.</strong> A veracidade das informações contidas nas petições é de
              total responsabilidade do usuário, haja vista que o sistema utiliza apenas as
              adequa ao recurso.
            </Typography>
          </ListItem>
          <ListItem>
            <Typography>
              <strong>1.3.</strong> A liberação do recurso ocorrerá automaticamente após a
              confirmação do pagamento realizado.
            </Typography>
          </ListItem>
          <ListItem>
            <Typography>
              <strong>1.4.</strong> O sistema utiliza teses fixas, elaboradas por advogados,
              sendo que estas são adicionadas na petição a partir das interações com o
              usuário.
            </Typography>
          </ListItem>
        </List>

        <Typography variant="h5" gutterBottom>
          Cláusula Segunda – Dos Limites da Responsabilidade da Plataforma
        </Typography>
        <List>
          <ListItem>
            <Typography>
              <strong>2.1.</strong> A plataforma Zero Multas utiliza as informações
              fornecidas pelo usuário para elaborar a petição e selecionar as teses de
              defesa adequadas ao caso contidas em nossa base de dados. Portanto, não há
              responsabilidade da plataforma caso as informações inseridas não sejam
              verídicas ou fidedignas.
            </Typography>
          </ListItem>
          <ListItem>
            <Typography>
              <strong>2.2.</strong> A plataforma Zero Multas não garante a procedência dos
              recursos, haja vista que cabe ao órgão competente realizar o julgamento.
            </Typography>
          </ListItem>
          <ListItem>
            <Typography>
              <strong>2.3.</strong> A plataforma Zero Multas não realizará o protocolo dos
              recursos, sendo essa responsabilidade inteiramente do usuário.
            </Typography>
          </ListItem>
          <ListItem>
            <Typography>
              <strong>2.4.</strong> A plataforma Zero Multas não se responsabiliza pela
              perda do desconto de pagamento antecipado das multas, haja vista que a
              decisão de ajuizar recursos é do próprio usuário.
            </Typography>
          </ListItem>
          <ListItem>
            <Typography>
              <strong>2.5.</strong> A plataforma Zero Multas irá manter cópia dos recursos
              gerados pelo usuário durante o prazo de 30 dias, sendo que depois serão
              excluídos da base de dados da plataforma.
            </Typography>
          </ListItem>
        </List>

        <Typography variant="h5" gutterBottom>
          Cláusula Terceira – Dos Danos e Erros
        </Typography>
        <List>
          <ListItem>
            <Typography>
              <strong>3.1.</strong> A plataforma Zero Multas ou as associações veiculares
              parceiras não serão responsáveis por danos materiais, morais ou lucros
              cessantes decorrentes do mau uso ou da incapacidade de uso da plataforma e
              dos recursos.
            </Typography>
          </ListItem>
          <ListItem>
            <Typography>
              <strong>3.2.</strong> Caso o usuário encontre erros técnicos, tipográficos ou
              fotográficos, deve entrar em contato com a assistência técnica pelo e-mail{" "}
              <Link href="mailto:contato@avarin.com.br">contato@avarin.com.br</Link>.
            </Typography>
          </ListItem>
          <ListItem>
            <Typography>
              <strong>3.3.</strong> A plataforma Zero Multas não garante a precisão dos
              recursos, haja vista a automatização do processo pela plataforma.
            </Typography>
          </ListItem>
        </List>

        <Typography variant="h5" gutterBottom>
          Cláusula Quarta – Das Responsabilidades do Usuário
        </Typography>
        <List>
          <ListItem>
            <Typography>
              <strong>4.1.</strong> O usuário se compromete a fornecer informações corretas e
              verdadeiras ao sistema.
            </Typography>
          </ListItem>
        </List>

        <Typography variant="h5" gutterBottom>
          Cláusula Quinta – Da Devolução e/ou Arrependimento
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>5.</strong> Serão devolvidos os valores pagos em até 07 dias após o
          pagamento, em caso de arrependimento.
        </Typography>

        <Typography variant="h5" gutterBottom>
          Cláusula Sexta – Da Possibilidade de Alteração do Termo
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>6.</strong> O presente termo poderá ser alterado mediante aviso prévio aos
          usuários.
        </Typography>

        <Typography variant="h5" gutterBottom>
          Cláusula Sétima - Do Foro Competente
        </Typography>
        <Typography variant="body1" paragraph>
          Fica estabelecido o foro da Comarca de Belo Horizonte, Minas Gerais, para dirimir
          quaisquer litígios.
        </Typography>
      </Container>
    </Box>
  );
};

export default TermsOfUse;
