import React, { useState } from 'react';
import {
  AppBar,
  Toolbar,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Box,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Login } from '@mui/icons-material';
import LogoutIcon from '@mui/icons-material/Logout';
import ReceiptIcon from '@mui/icons-material/Receipt';
import { Link as RouterLink } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';

const Navbar = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { authToken, user, logout } = useAuth();

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    logout();
    handleMenuClose();
  };

  return (
    <AppBar
      position="static"
      elevation={0}
      sx={{
        backgroundColor: 'background.default',
        borderBottom: '4px solid',
        borderColor: 'primary.main',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
      }}
    >
      <Toolbar>
        {/* Logo */}
        <Box
          component={RouterLink}
          to="/"
          sx={{
            flexGrow: 1,
            textDecoration: 'none',
            display: 'flex',
            justifyContent: { xs: 'center', md: 'flex-start' }, // Responsivo
            alignItems: 'center',
          }}
        >
          <Box
            component="img"
            src="/logomultas.png"
            alt="Zero Multas Logo"
            sx={{
              height: { xs: 50, sm: 70 },
              marginLeft: '50px',
              width: 'auto',
              '&:hover': {
                opacity: 0.8,
              },
            }}
          />
        </Box>

        {/* Botões para telas grandes */}
        <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
          <Button
            component={RouterLink}
            to="/define-violation"
            sx={{
              color: 'primary.main',
              fontWeight: 600,
              textTransform: 'uppercase',
              borderRadius: 2, // Remove arredondamento
              '&:hover': {
                color: 'secondary.main',
                backgroundColor: 'transparent',
              },
              mr: 2,
            }}
          >
            Nova Petição
          </Button>
          {authToken && user && (
            <>
              <Button
                component={RouterLink}
                to="/user-petitions"
                startIcon={<ReceiptIcon />}
                sx={{
                  color: 'primary.main',
                  fontWeight: 600,
                  textTransform: 'uppercase',
                  borderRadius: 2, // Remove arredondamento
                  '&:hover': {
                    color: 'secondary.main',
                  },
                  mr: 2,
                }}
              >
                Meus Pedidos
              </Button>
              <Button
                startIcon={<LogoutIcon />}
                onClick={handleLogout}
                sx={{
                  color: 'secondary.main',
                  fontWeight: 600,
                  textTransform: 'uppercase',
                  borderRadius: 2, // Remove arredondamento
                  border: '1px solid',
                  borderColor: 'secondary.main',
                  '&:hover': {
                    backgroundColor: 'secondary.main',
                    color: 'primary.main',
                  },
                }}
              >
                Sair
              </Button>
            </>
          )}
          {!authToken && (
            <Button
              component={RouterLink}
              to="/login"
              variant="contained"
              color="secondary"
              endIcon={<Login />}
              sx={{
                fontWeight: 700,
                textTransform: 'uppercase',
                borderRadius: 2, // Remove arredondamento
                px: 3,
                '&:hover': {
                  backgroundColor: '#FFC107',
                  color: 'primary.main',
                },
              }}
            >
              Acessar
            </Button>
          )}
        </Box>

        {/* Menu responsivo - Hamburger */}
        <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
          <IconButton
            size="large"
            edge="start"
            color="black"
            aria-label="menu"
            onClick={handleMenuOpen}
          >
            <MenuIcon />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem component={RouterLink} to="/" onClick={handleMenuClose}>
              Home
            </MenuItem>
            <MenuItem component={RouterLink} to="/define-violation" onClick={handleMenuClose}>
              Nova Petição
            </MenuItem>
            {authToken && user && (
              <>
                <MenuItem component={RouterLink} to="/user-petitions" onClick={handleMenuClose}>
                  Meus Pedidos
                </MenuItem>
                <MenuItem onClick={handleLogout}>Sair</MenuItem>
              </>
            )}
            {!authToken && (
              <MenuItem component={RouterLink} to="/login" onClick={handleMenuClose}>
                Logar
              </MenuItem>
            )}
          </Menu>
        </Box>
      </Toolbar>

      {/* Linha inferior amarela */}
      <Box
        sx={{
          height: '4px',
          backgroundColor: 'secondary.main',
        }}
      />
    </AppBar>
  );
};

export default Navbar;
